import React, { useEffect, useRef, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const InactivityTimeout: React.FC = () => {
  const totalTimeout = 15 * 60 * 1000; 
  const navigate = useNavigate();
  const location = useLocation();
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const handleLogout = useCallback(() => {
    if (location.pathname !== '/login') {
      sessionStorage.clear();
      sessionStorage.setItem('logoutByInactivity', 'true'); 
      navigate("/login");
    }
  }, [navigate, location.pathname]);

  const resetTimeout = useCallback(() => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);

    timeoutRef.current = setTimeout(() => {
      handleLogout();
    }, totalTimeout);
  }, [handleLogout, totalTimeout]);

  const handleActivity = useCallback(() => {
    resetTimeout();
  }, [resetTimeout]);

  useEffect(() => {
    if (location.pathname !== '/login') {
      const events = ['mousemove', 'keydown', 'click', 'scroll', 'touchstart', 'focus'];
      events.forEach((event) => window.addEventListener(event, handleActivity));

      const handleVisibilityChange = () => {
        if (document.visibilityState === 'visible') {
          resetTimeout();
        }
      };
      document.addEventListener('visibilitychange', handleVisibilityChange);

      resetTimeout(); 

      return () => {
        clearTimeout(timeoutRef.current!);
        events.forEach((event) => window.removeEventListener(event, handleActivity));
        document.removeEventListener('visibilitychange', handleVisibilityChange);
      };
    } else {
      const logoutByInactivity = sessionStorage.getItem('logoutByInactivity');
      if (logoutByInactivity === 'true') {
        alert("Tu sesión ha expirado por inactividad.");
        sessionStorage.removeItem('logoutByInactivity');
      }
    }
  }, [handleActivity, resetTimeout, location.pathname]);

  return null; 
};

export default InactivityTimeout;
