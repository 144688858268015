import React from "react";
import { Doughnut } from "react-chartjs-2";
import numeral from "numeral"; 

import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  ChartData,
  ChartOptions,
} from "chart.js";

numeral.register("locale", "es-ES", {
  delimiters: {
    thousands: ".", 
    decimal: ",",   
  },
  abbreviations: {
    thousand: "k",
    million: "M",
    billion: "B",
    trillion: "T",
  },
  ordinal: (number: number) => {
    return number === 1 ? "er" : "o";
  },
  currency: {
    symbol: "$",
  },
});

numeral.locale("es-ES");

ChartJS.register(ArcElement, Tooltip, Legend);

interface DoughnutChartProps {
  chartData: ChartData<"doughnut">;
}

const DoughnutChart: React.FC<DoughnutChartProps> = ({ chartData }) => {
  let total = Number(chartData.datasets[0].data.reduce((a, b) => a + b, 0)); 
  let cumplimiento = chartData.datasets[0].data[0]; 
  const porcentajeCumplimiento = total > 0 ? ((cumplimiento / total) * 100).toFixed(0) : "0";
  
  total = isNaN(total) ? 0 : Math.abs(total);
  cumplimiento = isNaN(cumplimiento) ? 0 : Math.abs(cumplimiento);

  const formatNumber = (number: number) => {
    if (isNaN(number)) return "0"; 
    return numeral(number).format('0,0'); 
  };
  
  const options: ChartOptions<"doughnut"> = {
    plugins: {
      tooltip: {
        enabled: true, 
        caretPadding: 10,
        yAlign: "bottom",
        callbacks: {
          label: function (context) {
            const value = Number(context.raw) || 0; 
            const formattedValue = formatNumber(value); 
            if (parseFloat(porcentajeCumplimiento) === 100) {
              return context.label === "Cumplimiento" ? `${context.label}: ${formattedValue}` : "";
            } else if (parseFloat(porcentajeCumplimiento) > 100) {
              return context.label === "Cumplimiento" ? `${context.label}: ${formattedValue}` : "";
            } else {
              return `${context.label}: ${formattedValue}`;
            }
          },
        },
      },
      legend: {
        display: false,
      },
    },
    cutout: "90%",
    circumference: 180,
    rotation: -90,
  };
  console.log('Total:', total);
  console.log('Cumplimiento:', cumplimiento);
  return (
    <div className="flex flex-col items-center justify-center w-full" style={{ marginTop: "-50px" }}>
      <Doughnut data={chartData} options={options} className="h-full w-full" />
      <div style={{ marginTop: "-110px" }} className="text-center">
        <p className="text-xl font-bold text-gray-500">Cajas Base</p>
        <p className="text-3xl font-bold mb-2">
          {formatNumber(Math.floor(cumplimiento))} 
        </p>        <div className="flex justify-center items-center">
          <div
            style={{
              width: "10px",
              height: "10px",
              backgroundColor: "#00BFFF",
              borderRadius: "50%",
              marginRight: "5px",
            }}
          ></div>
          <p style={{ margin: 0 }}>
            <span className="font-bold mr-4">{porcentajeCumplimiento}%</span>{" "}
            <span className="text-gray-500">Cumplimiento</span>
          </p>
        </div>
      </div>
      <div className="w-full flex flex-row justify-between items-center mt-3">
        <p className="font-bold text-gray-500">Total Contratado</p>
        <p className="font-bold text-xl">{formatNumber(total)}</p> 
      </div>
    </div>
  );
};

export default DoughnutChart;
