import { Field, Input } from "@headlessui/react";
import LogoDDC from "../../assets/Logo-DDC";
import Widget from "../components/widget";
import DDCButton from "../components/buttons"
import clsx from 'clsx'
import useLogin from "./useLogin";
import DDCAlert from "../components/alerts";
import { useEffect, useState } from "react";
import { CogIcon } from "@heroicons/react/20/solid";
import Modal from "../components/modal";
const Login = () => {
  const { email, setEmail, password, setPassword, error, handleLogin, loading } = useLogin();
  const [toggleAlert, setToggleAlert] = useState(false);

  const [modalContent, setModalContent] = useState<React.ReactNode | string>(null);
  const [isModalVisible, setModalVisible] = useState(false);

  const openModal = (content: React.ReactNode | string) => {

    setModalContent(content);
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  useEffect(() => {
    if (error) {
      setToggleAlert(true);
    }
  }, [error]);

  const handleCloseAlert = () => {
    setToggleAlert(false)
  };

  const handleSupportClick = () => {
    openModal(<div>
      Si tienes algún problema, comunícate a través de nuestro correo electrónico
      <span className="text-ddc-blue"> soporteTI@ddc.cl</span>
    </div>);
  };
  
  const handleRecoverPasswordClick = () => {
    window.location.href = "https://ambitious-plant-061bff30f.4.azurestaticapps.net/recover-password?returnUrl=portalProductores";
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleLogin(); // Ejecuta la función del login
    }
  };
  return (
    <div className="login-container overflow-x-hidden z-10">
      <div className="background-color-ddc"></div>
      <div className="background-gray"></div>
      {loading && (
        <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="text-white flex items-center space-x-3">
            <CogIcon className="w-8 h-8 animate-spin" /> {/* Ícono de coche girando */}
            <span className="text-xl">Accediendo...</span>
          </div>
        </div>
      )}
      <Widget classNameContainer="Widget rounded-md bg-white w-fit self-center">
        <div className="flex flex-col justify-center max-w-sm min-w-96">
          <LogoDDC className="mt-4 mb-10 self-center" />
          <h3 className="font-semibold text-2xl mb-1 mx-3 dev-font-f-titi-web">Ingresar al Portal de Productores</h3>
          <p className="text-font-blue-gray text-sm mb-8 mx-3 dev-font-f-roboto">Accede a tu cuenta con tus credenciales.</p>

          {/*{error && <p className="text-red-500">{error}</p>}*/}
          <Field>
            <Input
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value.trim())}
              className={`
                block w-full border border-border-gray rounded-md py-1.5 px-3 text-sm/6 h-11 mb-5
                ${toggleAlert ? 'border-[#E36464] bg-[#E3646426] text-[#E36464]' : 'bg-white/5 focus:border-input-focus-color'}
                focus:outline-none focus:outline-2 focus:-outline-offset-2
              `}
            />
          </Field>
          <Field>
            <Input
              type="password"
              placeholder="Contraseña"
              value={password}
              onKeyDown={handleKeyDown} 
              onChange={(e) => setPassword(e.target.value.trim())}
              className={clsx(
                'block w-full border border-border-gray rounded-md bg-white/5 py-1.5 px-3 text-sm/6 h-11 mb-5',
                'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:border-input-focus-color'
              )}
            />
          </Field>

          <DDCButton
            // click_action={() => window.location.href = '/dashboard'}
            click_action={handleLogin}
            b_text="Acceder" />

          <span className="text-base self-center mt-4 dev-font-f-roboto">¿Olvidaste tu contraseña? <a href="#" onClick={handleRecoverPasswordClick} className="text-ddc-blue">Recuperar acceso</a></span>
          <span className="text-base self-center dev-font-f-roboto">¿Aún no tienes tu acceso? <a href="#"  onClick={handleSupportClick} className="text-ddc-blue">Solicítalo aquí</a></span>
          <span className="text-sm text-center text-gray-500">versión beta 1.0.4</span>
        </div>
      </Widget>
      <DDCAlert type={`error`}
        message={'Error de credenciales'}
        subtitle={error.message}
        toggleAlert={toggleAlert}
        setToggleAlert={setToggleAlert}
        onClose={handleCloseAlert}
      />

      <Modal
        isVisible={isModalVisible}
        onClose={closeModal}
        title="Ayuda y Soporte"
        content={modalContent}
      />
    </div>
  )
}

export default Login;